<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section open section-pb48">
          <h1>Город Махачкала</h1>
          <EditorJSComponent :text="JSON.parse(mycity.description)" v-if="mycity && mycity.description" />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "MyCityAbout",
  components: {
    EditorJSComponent,
  },
  async asyncData({ store }) {
    await store.dispatch("GET_MYCITY_PAGE");
  },
  computed: {
    mycity() {
      return this.$store.state?.mycity_page;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
